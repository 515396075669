import { useFetch } from 'nuxt/app';
import { FormatResponse } from 'seed-core';

import { useRequest } from '@/composables/useRequest';
import { COMMON_ERROR_MESSAGE_KEY } from '@/constants/error.const';
import { ApiMethod, HeaderTypes } from '@/enums/api.enum';
import type {
  FileMeta,
  MultipleFilesMetaDataRequest,
  UploadFile,
  UploadFileResponse
} from '@/types/common/file.type';
import { generateHeader } from '@/utils/api.util';
import { getConfigs, showCommonErrorDialog } from '@/utils/common.util';
import { setMultiFileFormData } from '@/utils/file.util';

// https://wiki.smilegate.net/pages/viewpage.action?spaceKey=SDKAPIDOCU&title=e_v1.0+upload+single+file
export const uploadFileApi = async (
  files: File[],
  category: string,
  convertWebp?: boolean
): Promise<UploadFile[] | undefined> => {
  const { APPLICATION_NO } = getConfigs();
  const formData = setMultiFileFormData({
    applicationNo: APPLICATION_NO,
    files,
    category,
    convertWebp
  });

  if (!formData) {
    return;
  }

  try {
    const res = await useRequest<{ data: UploadFileResponse }>('clouds/v1.0/file', {
      headers: {
        ...generateHeader('caller-id', 'caller-detail')
      },
      method: ApiMethod.Post,
      data: formData,
      formatResponse: FormatResponse.Custom,
      cancelAfter: 0
    });

    return res.data?.data?.list;
  } catch (err) {
    await showCommonErrorDialog(COMMON_ERROR_MESSAGE_KEY);
    return undefined;
  }
};

// TODO: new indie-studio-v3
export const convertGifToImage = async (image: File): Promise<Blob | null> => {
  const formData = new FormData();
  formData.append('image', image);

  const res = await useFetch<Blob>('convert/gif2webp', {
    baseURL: 'https://image-dev2.onstove.com',
    method: ApiMethod.Post,
    body: formData
  });

  return res?.data.value;
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/e_v1.0+get+multiple+files+meta+data
export const fetchMultipleFilesMetaDataApi = async (
  request: MultipleFilesMetaDataRequest
): Promise<FileMeta[] | undefined> => {
  const { data } = await useRequest<{ data: { list: FileMeta[] } }>('clouds/v1.0/file', {
    headers: {
      ...generateHeader(HeaderTypes.XLang, HeaderTypes.XNation, HeaderTypes.CallerId)
    },
    method: ApiMethod.Get,
    params: {
      service_type: request.serviceType,
      service_id: request.serviceId,
      user_type: request.userType,
      user_id: request.userId,
      file_id_list: `["${request.fileIdList.join('","')}"]`,
      properties: `["${request.properties.join('","')}"]`,
      timestemp: new Date().getTime()
    },
    formatResponse: FormatResponse.Custom
  });
  return data?.data?.list;
};
